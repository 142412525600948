import React, {Component} from 'react';
import '../body/body.css';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import {withTranslation} from 'react-i18next';
import instance from '../../services/apiService';
import CardList from '../cardList/cardList';
import {PS_ID, SITE} from '../../constants/common';

/* Assets */
import arrowIcon from '../../assets/images/arrow.svg';
import copyIcon from '../../assets/images/copyIcon.svg';
import copiedSuccess from '../../assets/images/copiedSuccess.svg';
import qrInProgress from '../../assets/images/qrPageStatuses.svg';
import halfCircle from '../../assets/images/halfCircles.svg';
import smallHalfCircle from '../../assets/images/smallHalfCircle.svg';

class QRBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteUrl: null,
      isCopied: false,
      copiedQrText: this.props?.qrCode
    };

    this.handelCopyText = this.handelCopyText.bind(this);
    this.handelCopyTextForQrCode = this.handelCopyTextForQrCode.bind(this);
    // test change
  }

  getSiteUrl(url) {
    return url?.toString()
      .includes('http')
    || url?.toString()
      .includes('https') ? url
      : `//${this.state.siteUrl}`;
  }

  handelCopyText(copiedText) {
    this.setState({
      isCopied: true,
      copiedText
    });

    navigator.clipboard.writeText(copiedText);
    setTimeout(() => {
      this.setState({isCopied: false});
    }, 5000);
  }

    handelCopyTextForQrCode = () => {
      this.setState({
        isCopied: true
      });
      navigator.clipboard.writeText(this.state.copiedQrText)
        .then(() => {
          setTimeout(() => {
            this.setState({isCopied: false});
          }, 5000);
        });
    }

    handleCopiedKey(copiedText) {
      const {t} = this.props;
      return Object.keys(copiedText.results).map((key) => (
        <div className="copy-text-wrapper flex-container">
          <p className="copy-text-wrapper__item">
            {copiedText.results[key]}
          </p>
          {this.state.isCopied ? (
            <>
              <span className="copy-success-message">
                {t('page.codeCopied')}
              </span>
              <div className="icon-wrapper copy-icon flex-container">
                <img src={copiedSuccess} alt="copied success icon" />
              </div>
            </>
          ) : (
            <div
              className="icon-wrapper copy-icon flex-container"
              onClick={() => this.handelCopyText(copiedText.results[key])}
            >
              <img
                src={copyIcon}
                alt="arrow icon"
              />
            </div>
          )}
        </div>
      ));
    }

    componentDidMount() {
      if (!this.props.backUrl && this.props.ServiceProviderId) {
        instance.get(`Merchant/merchantsiteurl?merchantSiteId=${Number(this.props?.ServiceProviderId)}`)
          .then((res) => {
            this.setState((prevState) => ({
              ...prevState,
              siteUrl: res.data
            }));
          })
          .catch((err) => err);
      } else {
        return this.props.backUrl;
      }
    }

    render() {
      const {
        t,
        createQR,
        isNew,
        backUrl
      } = this.props;
      const hasBackButton = isNew === SITE.IS_NEW || (isNew !== SITE.IS_NEW && backUrl) || createQR;
      // console.log('testing', hasBackButton);
      const hasBackButtonQR = `card-content-wrapper qr ${hasBackButton ? 'has-back-button' : ''}`;
      return (
        <main className="main-wrapper">
          {/* container for card */}
          <div className={hasBackButtonQR}>
            <section className="transaction-card">
              <picture className="transaction-status-img-wrapper">
                <img src={qrInProgress} alt="In Progress transaction" />
              </picture>
              <div className="inProcess">
                <h3 className="transaction-status">
                  {t('page.transactionInProcess')}
                </h3>
                {this.props.PaymentSystemId !== PS_ID.PIX && this.props.PaymentSystemId !== PS_ID.PIX_TO_PAY && (
                <p className="message-info">
                  Scan the QR or copy the code to complete the transaction.
                </p>
                )}
                {(this.props.PaymentSystemId === PS_ID.PIX || this.props.PaymentSystemId === PS_ID.PIX_TO_PAY) && (
                <p className="message-info">
                  {t('page.ScanTheQROrCopy')}
                </p>
                )}
              </div>
              <div className="qrCode-container">
                {this.props?.qrImage && !this.props.createQR && (
                <div className="qrCode-wrapper">
                  <img src={decodeURIComponent(this.props.qrImage)} alt="QR CODE" />
                </div>
                )}
                {/* Remove this part, after getting  generateQrCode key and value */}
                {this.props.createQR && (
                <div className="qrCode-wrapper">
                  <QRCode value={this.props.qrCode} size="250" />
                </div>
                )}
                {this.props.generateQrCode && (
                <div className="qrCode-wrapper">
                  <QRCode value={this.props.generateQrCode} size="250" />
                </div>
                )}
                {this.props?.requestParamQr && (
                <>
                  <div className="qrCode-wrapper">
                    <img src={decodeURIComponent(this.props.requestParamQr)} alt="QR CODE" />
                  </div>
                </>
                )}
                {this.props?.requestParamBarCode && (
                <div className="qrCode-wrapper barCode-wrapper-base">
                  <img
                    src={`data:image/jpeg;base64,${this.props.requestParamBarCode}`}
                    alt="QR CODE"
                  />
                </div>
                )}
                {this.props?.barCode && (
                <div className="barcode-wrapper">
                  <Barcode value={this.props.barCode} />
                </div>
                )}
                {this.props?.qrCode && !this.props.createQR && (
                <div className="copy-text-wrapper flex-container">
                  <p className="copy-text-wrapper__item">
                    {this.props.qrCode}
                  </p>
                  {this.state.isCopied
                    ? (
                      <>
                        <span className="copy-success-message">
                          {t('page.codeCopied')}
                        </span>
                        <div className="icon-wrapper copy-icon flex-container">
                          <img src={copiedSuccess} alt="copied success icon" />
                        </div>
                      </>
                    )
                    : (
                      <div
                        className="icon-wrapper copy-icon flex-container"
                        onClick={this.handelCopyTextForQrCode}
                      >
                        <img
                          src={copyIcon}
                          alt="arrow icon"
                        />
                      </div>
                    )}
                </div>
                )}
                {/* <div>*/}
                {/*  {this.handleCopiedKey(this.props?.CopiedText)}*/}
                {/* </div>*/}
              </div>
              <CardList
                TransactionID={this.props.TransactionID}
                UserID={this.props.UserID}
                Amount={this.props.Amount}
                Currency={this.props.Currency}
                CryptoCurrency={this.props.CryptoCurrency}
                CryptoAmount={this.props.CryptoAmount}
                Language={this.props.Language}
                CopyWalletAddress={this.props.CopyWalletAddress}
                CopyDestinationTag={this.props.CopyDestinationTag}
              />
            </section>
            {/* bottom zigzag image */}
            <div className="half-circles-wrapper">
              <img src={halfCircle} className="half-circles" alt="half circles" />
              <img src={smallHalfCircle} className="small-half-circles" alt="half circles" />
            </div>
          </div>
          <>
            {!this.props.isIframeComponent && this.props?.isNew === SITE.IS_NEW && this.props.ServiceProviderId ? (
              <div className="button-container">
                <a
                  className="card-btn flex-container"
                  href={this.getSiteUrl(this.state.siteUrl)}
                >
                  <div className="icon-wrapper flex-container">
                    <img src={arrowIcon} alt="arrow icon" />
                  </div>
                  {t('page.backToWebsite')}
                </a>
              </div>
            ) : (!this.props.isIframeComponent && this.props?.isNew !== SITE.IS_NEW && this.props.backUrl && this.props.ServiceProviderId) ? (
              <div className="button-container">
                <a
                  className="card-btn flex-container"
                  href={this.getSiteUrl(this.props.backUrl)}
                >
                  <div className="icon-wrapper flex-container">
                    <img src={arrowIcon} alt="arrow icon" />
                  </div>
                  {t('page.backToWebsite')}
                </a>
              </div>
            ) : null}
            {this.props.createQR && (
            <div className="button-container">
              <a
                className="card-btn oplati flex-container"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.qrCode}
              >
                {t('page.continueWithPayment')}
              </a>
            </div>
            )}
          </>
        </main>
      );
    }
}

export default withTranslation()(QRBody);
