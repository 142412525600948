import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
// translations
import en from './en.json';
import ru from './ru.json';
import pt from './pt.json';
import tr from './tr.json';
import hy from './hy.json';
import bs from './bs.json';
import hr from './hr.json';
import sr from './sr.json';
import es from './es.json';

const resources = {
  en: {
    translation: en
  },
  ru: {
    translation: ru
  },
  tr: {
    translation: tr
  },
  pt: {
    translation: pt
  },
  hy: {
    translation: hy
  },
  bs: {
    translation: bs
  },
  hr: {
    translation: hr
  },
  sr: {
    translation: sr
  },
  es: {
    translation: es
  }
};
const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.get('lang');

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: lang,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
