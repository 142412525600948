import React from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import notFound from '../../assets/images/not_found.png';
import './notFoundPage.css';

function NotFoundPage() {
  const history = useHistory();
  const {t} = useTranslation();
  return (
    <main className="loaderPage not-found-wrapper">
      <div className="page__image">
        <img src={notFound} alt="" />
      </div>
      <div className="nfp-message">
        <p>
          {t('page.wrongWay')}
          <br />
          {t('page.goBack')}
        </p>
      </div>
      <button
        type="button"
        className="card-btn flex-container"
        onClick={() => history.goBack()}
      >
        {t('page.back')}
      </button>
    </main>
  );
}

export default NotFoundPage;
