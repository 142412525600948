import React, {useState} from 'react';
import './rating.css';
import {useTranslation} from 'react-i18next';
import {RATING_INFO} from '../../constants/common';


function Rating(props) {
  const [selectedRating, setSelectedRating] = useState(null);
  const {t} = useTranslation();

  const handleRate = (index) => {
    setSelectedRating(index);
    props.onChange(index);
  };

  return (
    <section className="rate-wrapper">
      <h2 className="rate-wrapper__title">{t('page.rateYourPaymentExperience')}</h2>
      <ul className="rating-list flex-container">
        {RATING_INFO.map((emotion) => (
          <li className="rating-list__item" onClick={() => handleRate(emotion.KEY)}>
            <picture className="rating-emojis">
              <img
                src={emotion.IMG}
                alt={emotion.NAME}
                className={`${selectedRating === emotion.KEY ? 'selected' : ''}`}
              />
            </picture>
            <span className="rating-emoji-label">{t(emotion.NAME)}</span>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default Rating;
