import React, {Component} from 'react';
import './body.css';
import {withTranslation} from 'react-i18next';
import Rating from '../rating/rating';
import FinalPageMessage from '../finalPageMessage/finalPageMessage';
import {
  PS_ID,
  SITE_ID,
  TRANSACTION_MESSAGE_TYPES,
  TRANSACTION_RESPONSE
} from '../../constants/common';
import instance from '../../services/apiService';

/* Assets */
import arrowIcon from '../../assets/images/arrow.svg';
import halfCircle from '../../assets/images/halfCircles.svg';
import smallHalfCircle from '../../assets/images/smallHalfCircle.svg';

class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRated: false,
      rating: 0,
      disabled: false,
      siteUrl: null,
      isCopied: false
    };

    this.handleRating = this.handleRating.bind(this);
  }

  handelCopyText(copiedText) {
    this.setState({
      isCopied: true,
      copiedText
    });

    return navigator.clipboard.writeText(copiedText);
  }

  getUrl(url) {
    return url?.toString()
      .includes('http')
    || url?.toString()
      .includes('https') ? url
      : `//${this.state.siteUrl}`;
  }

  handleRating(index) {
    if (index) {
      const data = {
        paymentSystemId: Number(this.props.PaymentSystemId),
        siteId: Number(this.props.ServiceProviderId),
        rateValue: index
      };

      instance.put('Merchant/setsitepaymentsystemrate', data)
        .then((res) => {
          if (res.status === TRANSACTION_RESPONSE.SUCCESS) {
            window.location.href = this.getUrl(this.state.siteUrl);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }

  componentDidMount() {
    console.log('calling inside body', this.props);
    instance.get(`Merchant/merchantsiteurl?merchantSiteId=${Number(this.props.ServiceProviderId)}`)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          siteUrl: res.data
        }));
      })
      .catch((err) => err);
  }

  render() {
    const {t} = this.props;
    return (
      <main className="main-wrapper">
        <div className="card-content-wrapper">
          <section className="transaction-card">
            <FinalPageMessage
              Result={this.props.Result}
              Message={this.props.Message}
              Type={this.props.Type}
              Code={this.props.Code}
              AccountHolder={this.props.AccountHolder}
              AccountBankName={this.props.AccountBankName}
              AccountNo={this.props.AccountNo}
              AccountIban={this.props.AccountIban}
              AccountBranch={this.props.AccountBranch}
              PaymentSystemName={this.props.PaymentSystemName}
              TransactionID={this.props.TransactionID}
              PaymentReference={this.props.PaymentReference}
              UserID={this.props.UserID}
              Amount={this.props.Amount}
              Currency={this.props.Currency}
              PaymentSystemId={this.props.PaymentSystemId}
              SiteId={this.props.ServiceProviderId}
            />
          </section>
          <div className="half-circles-wrapper">
            <img src={halfCircle} className="half-circles" alt="half circles" />
            <img src={smallHalfCircle} className="small-half-circles" alt="half circles" />
          </div>
          {this.props.Result === TRANSACTION_MESSAGE_TYPES.SUCCESS
          && this.props.ServiceProviderId !== SITE_ID.SITE77
          && this.props.ServiceProviderId !== SITE_ID.SITE81
          && this.props.ServiceProviderId !== SITE_ID.SITE1476 ? (
            <Rating
              onChange={(index) => this.handleRating(index)}
            />
            ) : null}
        </div>
        {!this.props.isIframeComponent && this.props.ServiceProviderId && this.props.ServiceProviderId !== SITE_ID.SITE81
        && this.props.ServiceProviderId !== SITE_ID.SITE1476
            && this.props.PaymentSystemId !== PS_ID.ZENNPAYZ_HAVALE ? (
              <div className="button-container">
                <a
                  className="card-btn flex-container"
                  href={this.getUrl(this.state.siteUrl)}
                >
                  <div className="icon-wrapper flex-container">
                    <img src={arrowIcon} alt="arrow icon" />
                  </div>
                  {t('page.backToWebsite')}
                </a>
              </div>
          ) : null}
      </main>
    );
  }
}

export default withTranslation()(Body);
