import React, {Component} from 'react';
import axios from 'axios';
import CheckOutPage from '../checkOutPage/checkOutPage';
import Loader from '../loader/loader';
import FinalPage from '../finalPage/finalPage';
import QRPage from '../QRPage/QRPage';

class Transaction extends Component {
  constructor(props) {
    super(props);
    console.log('log in Transaction this.props', this.props);
    this.state = {
      transactionId: this.props.isIframeComponent ? this.props.id : this.props.match.params.id,
      // eslint-disable-next-line react/no-unused-state
      transactionFlow: 0,
      // eslint-disable-next-line react/no-unused-state
      requestUrl: '',
      // eslint-disable-next-line react/no-unused-state
      requestMethod: '',
      // eslint-disable-next-line react/no-unused-state
      requestParam: '',
      // eslint-disable-next-line react/no-unused-state
      httpRequestMethod: ''
    };
  }

  componentDidMount() {
    console.log('request to transactionflow', this.state);
    // //axios.get(`https://localhost:44309/api/transaction/transactionflow?api-version=1&transactionId=${this.state.transactionId}`)
    // /axios.get(`https://paymentgatewayapi.devdigitain.com/api/transaction/transactionflow?api-version=1&transactionId=${this.state.transactionId}`)
    // //axios.get(`https://pgnew.betadigitain.com/api/transaction/transactionflow?api-version=1&transactionId=${this.state.transactionId}`)
    axios
      .get(
        `https://pygapi.letspayments.com/api/transaction/transactionflow?api-version=1&transactionId=${this.state.transactionId}`
      )
      .then((res) => {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          transactionFlow: res.data.transactionFlow,
          // eslint-disable-next-line react/no-unused-state
          requestUrl: res.data.requestUrl,
          // eslint-disable-next-line react/no-unused-state
          requestMethod: res.data.requestMethod,
          // eslint-disable-next-line react/no-unused-state
          requestParam: res.data.requestParam,
          // eslint-disable-next-line react/no-unused-state
          httpRequestMethod: res.data.httpRequestMethod
        });
      })
      .catch(() => {});
  }


  render() {
    const self = this;
    console.log('self.state', self.state);
    switch (self.state.transactionFlow) {
      case 1:
        return (
          <CheckOutPage
            requestUrl={self.state.requestUrl}
            requestMethod={self.state.requestMethod}
            requestParam={self.state.requestParam}
            httpRequestMethod={self.state.httpRequestMethod}
          />
        );
      case 2:
        return (
          <FinalPage
            url={self.state.requestUrl}
            requestParam={self.state?.requestParam}
            isIframeComponent={this.props.isIframeComponent}
          />
        );
      case 3:
        return (
          <QRPage
            url={self.state.requestUrl}
            requestParamQr={self.state?.requestParam?.QR}
            requestParamBarCode={self.state?.requestParam?.barCode}
            isIframeComponent={this.props.isIframeComponent}
          />
        );
      default:
        return <Loader />;
    }
  }
}

export default Transaction;
