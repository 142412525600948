import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Transaction from './components/transaction/transaction';
import FinalPage from './components/finalPage/finalPage';
import CheckOutPage from './components/checkOutPage/checkOutPage';
import NotFoundPage from './components/notFoundPage/notFoundPage';
import QRPage from './components/QRPage/QRPage';
// eslint-disable-next-line
import i18n from './configs/i18n/i18n';

function ResultPagesRoute() {
  if (window.location.pathname.includes('iframe/transaction')) {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const transactionId = searchParams.get('transactionId');

    return (
      <Transaction
        isIframeComponent
        id={transactionId}
      />
    );
  } if (window.location.pathname.includes('iframe/qrPage')) {
    return (
      <QRPage
        isIframeComponent
      />
    );
  }
  console.log('route index.js rendered');
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/transactionId=:id" component={Transaction} />
        <Route exact path="/checkout" component={CheckOutPage} />
        <Route exact path="/finalPage" component={FinalPage} />
        <Route exact path="/qrPage" component={QRPage} />
        <Route exact path="/notFoundPage" component={NotFoundPage} />
        <Route component={FinalPage} />
        <Route component={QRPage} />
      </Switch>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <ResultPagesRoute />,
  document.getElementById('root')
);
