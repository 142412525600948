import React, {Component} from 'react';
import '../body/body.css';
import {withTranslation} from 'react-i18next';
import copyIcon from '../../assets/images/copyIcon.svg';
import {LINE_INDEXES, PS_ID} from '../../constants/common';
import copiedSuccess from '../../assets/images/copiedSuccess.svg';


class CardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copiedLineIndex: null
    };
  }

  handelCopyText(copiedText, lineIndex) {
    navigator.clipboard.writeText(copiedText);
    this.setState({copiedLineIndex: lineIndex});
    setTimeout(() => {
      this.setState({copiedLineIndex: null});
    }, 5000);
  }

  render() {
    const {
      TransactionID,
      PaymentReference,
      CopyWalletAddress,
      CopyDestinationTag,
      UserID,
      AccountNo,
      AccountIban,
      AccountHolder,
      AccountBranch,
      AccountBankName,
      Amount,
      Currency,
      CryptoCurrency,
      CryptoAmount,
      PaymentSystemId,
      t
    } = this.props;

    return (
      <ul className="card-list">
        {TransactionID && (
          <li className="card-list__item">
            <h3 className="card-list__item-label">
              {t('page.transactionID')}
            </h3>
            <p className="card-list__item-value">{TransactionID}</p>
          </li>
        )}
        {PaymentReference && (
          <li className="card-list__item">
            <h3 className="card-list__item-label">{t('page.paymentReference')}</h3>
            <p className="card-list__item-value">{PaymentReference}</p>
          </li>
        )}
        {CopyWalletAddress && (
        <li className="card-list__item">
          <h3 className="card-list__item-label">
            {t('page.walletAddress')}
          </h3>
          <div className="iban_with_copy_icon">
            <p className="copy-text-wrapper__item">{CopyWalletAddress}</p>
            {this.state.copiedLineIndex === LINE_INDEXES.ZERO
              ? (
                <>
                  <span className="copy-success-message">
                    {t('page.codeCopied')}
                  </span>
                  <div className="icon-wrapper copy-icon flex-container">
                    <img src={copiedSuccess} alt="copied success icon" />
                  </div>
                </>
              )
              : (
                <div
                  className="icon-wrapper copy-icon flex-container"
                  onClick={() => this.handelCopyText(CopyWalletAddress, LINE_INDEXES.ZERO)}
                >
                  <img
                    src={copyIcon}
                    alt="arrow icon"
                  />
                </div>
              )}
          </div>
        </li>
        )}
        {CopyDestinationTag && (
        <li className="card-list__item">
          <h3 className="card-list__item-label">
            {t('page.destinationTag')}
          </h3>
          <div className="iban_with_copy_icon">
            <p className="copy-text-wrapper__item">{CopyDestinationTag}</p>
            {this.state.copiedLineIndex === LINE_INDEXES.ONE
              ? (
                <>
                  <span className="copy-success-message">
                    {t('page.codeCopied')}
                  </span>
                  <div className="icon-wrapper copy-icon flex-container">
                    <img src={copiedSuccess} alt="copied success icon" />
                  </div>
                </>
              )
              : (
                <div
                  className="icon-wrapper copy-icon flex-container"
                  onClick={() => this.handelCopyText(CopyDestinationTag, LINE_INDEXES.ONE)}
                >
                  <img
                    src={copyIcon}
                    alt="arrow icon"
                  />
                </div>
              )}
          </div>
        </li>
        )}
        {UserID && (
          <li className="card-list__item">
            <h3 className="card-list__item-label">
              {t('page.userID')}
            </h3>
            <p className="card-list__item-value">{UserID}</p>
          </li>
        )}
        {AccountNo && (
          <li className="card-list__item">
            <h3 className="card-list__item-label">
              {t('page.accountNumber')}
            </h3>
            <p className="card-list__item-value">{AccountNo}</p>
          </li>
        )}
        {AccountIban && (
          <li className="card-list__item">
            <h3 className="card-list__item-label">
              {t('page.iban')}
            </h3>
            <div className="iban_with_copy_icon">
              <p className="card-list__item-value card-list__item-value--max-length">{AccountIban}</p>
              {PaymentSystemId === PS_ID.ZENNPAYZ_HAVALE && (this.state.copiedLineIndex === LINE_INDEXES.TWO
                ? (
                  <>
                    <span className="copy-success-message">
                      {t('page.codeCopied')}
                    </span>
                    <div className="icon-wrapper copy-icon flex-container">
                      <img src={copiedSuccess} alt="copied success icon" />
                    </div>
                  </>
                )
                : (
                  <div
                    className="icon-wrapper copy-icon flex-container"
                    onClick={() => this.handelCopyText(AccountIban, LINE_INDEXES.TWO)}
                  >
                    <img
                      src={copyIcon}
                      alt="arrow icon"
                    />
                  </div>
                ))}
            </div>
          </li>
        )}
        {AccountHolder && (
          <li className="card-list__item">
            <h3 className="card-list__item-label">
              {t('page.accountHolder')}
            </h3>
            <div className="iban_with_copy_icon">
              <p className="card-list__item-value">{AccountHolder}</p>
              {PaymentSystemId === PS_ID.ZENNPAYZ_HAVALE && (this.state.copiedLineIndex === LINE_INDEXES.THREE
                ? (
                  <>
                    <span className="copy-success-message">
                      {t('page.codeCopied')}
                    </span>
                    <div className="icon-wrapper copy-icon flex-container">
                      <img src={copiedSuccess} alt="copied success icon" />
                    </div>
                  </>
                )
                : (
                  <div
                    className="icon-wrapper copy-icon flex-container"
                    onClick={() => this.handelCopyText(AccountHolder, LINE_INDEXES.THREE)}
                  >
                    <img
                      src={copyIcon}
                      alt="arrow icon"
                    />
                  </div>
                ))}
            </div>
          </li>
        )}
        {AccountBranch && (
          <li className="card-list__item">
            <h3 className="card-list__item-label">
              {t('page.accountBranch')}
            </h3>
            <p className="card-list__item-value">{AccountBranch}</p>
          </li>
        )}
        {AccountBankName && (
          <li className="card-list__item">
            <h3 className="card-list__item-label">
              {t('page.bankName')}
            </h3>
            <p className="card-list__item-value">{AccountBankName}</p>
          </li>
        )}
        {Amount && (
          <li className="card-list__item card-list__item--last-child">
            <h3 className="card-list__item-label">
              {t('page.amount')}
            </h3>
            <p className="card-list__item-value">
              {Amount}
              {' '}
              <span>{Currency}</span>
            </p>
          </li>
        )}
        {CryptoCurrency && CryptoAmount && (
        <li className="card-list__item list-item--crypto">
          <p className="card-list__item-value">
            {CryptoAmount}
            {' '}
            <span>{CryptoCurrency}</span>
          </p>
        </li>
        )}
      </ul>
    );
  }
}

export default withTranslation()(CardList);
