import {ERROR_CODES} from '../constants/common';

export const parseURL = (url) => {
  const urlStr = new URL(url);
  const params = new URLSearchParams(urlStr.search);
  const obj = Object.fromEntries([...params]);


  return {
    params: obj
  };
};

/**
 * Returns the received message
 * @param {string} code
 */
export const returnMessage = (code) => {
  const messageMapping = {
    [ERROR_CODES.DEFAULT_BETERA_MESSAGE]: 'page.operationRejected'
  };

  return messageMapping[code];
};

