/**
 * Configurable data in project
 *
 * @const {Object}
 */


const Config = {
  apiURLs: {
    baseURL: 'https://pygadminapi.letspayments.com/api/'
  }
};

export default Config;
