import React, {Component} from 'react';
import Loader from '../loader/loader';

class CheckOutPage extends Component {
  // window.location = props.requestUrl;
  constructor(props) {
    super(props);
    this.state = {
      formDatas: []
    };
  }

  componentDidMount() {
    const propertis = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const property in this.props.requestParam) {
      propertis.push({name: property, value: this.props.requestParam[`${property}`]});
    }

    this.setState({formDatas: propertis});
  }

  componentDidUpdate() {
    if (this.props.requestMethod === 1) {
      document.getElementById('checkoutForm').submit();
    }
  }

  render() {
    return (
      <div>
        <Loader />
        {
          this.props.requestMethod === 1
            ? <Form url={this.props.requestUrl} formDatas={this.state.formDatas} httpRequestMethod={this.props.httpRequestMethod} />
            : window.location = this.props.requestUrl
        }
      </div>
    );
  }
}

export default CheckOutPage;

function Form(props) {
  return (
    <form action={props.url} method={props.httpRequestMethod} id="checkoutForm">
      {
        props.formDatas && props.formDatas.map((fdata) => <input type="hidden" id={fdata.name} name={fdata.name} value={fdata.value?.toString()} />)
      }
    </form>
  );
}
