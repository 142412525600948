import React, {Component} from 'react';
import Body from '../body/body';
import NotFoundPage from '../notFoundPage/notFoundPage';
import {parseURL} from '../../helper/helper';

class FinalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      url: this.props.url ? this.props.url : window.location.href,
      isInvalidUrl: false
    };

    this.parseURL = parseURL.bind(this);
  }

  componentDidMount() {
    const {url} = this.state;

    // Replace any HTML-encoded entities like &amp; with the correct characters
    const cleanedUrl = url.replace(/&amp;/g, '&');

    // Check if URL contains valid query parameters
    if (!cleanedUrl.includes('?')) {
      this.setState({isInvalidUrl: true});
    } else {
      // Parse the cleaned URL
      const urlObj = this.parseURL(cleanedUrl);
      const urlParams = this.decodeParams(urlObj.params);
      this.setState({params: urlParams});
    }
  }

  // Helper method to decode the parameters
  decodeParams(params) {
    const decodedParams = {};
    Object.keys(params).forEach((key) => {
      decodedParams[key] = decodeURIComponent(params[key]);
    });
    return decodedParams;
  }

  render() {
    const {isInvalidUrl, params} = this.state;

    if (isInvalidUrl) {
      return <NotFoundPage />;
    }

    return (
      <>
        {params.sp ? (
          <Body
            TransactionID={params.clientRequestId}
            Type={params.t}
            Result={params.r}
            Message={params.mes}
            AccountHolder={params.accountHolder}
            AccountBankName={params.accountBankName}
            AccountNo={params.accountNo}
            AccountIban={params.accountIban}
            AccountBranch={params.accountBranch}
            UserID={params.customerId}
            ExternalID={params.pstransid}
            Amount={params.transactionAmount}
            Currency={params.currency}
            ServiceProviderId={params.sp}
            PaymentSystemId={params.ps}
            PaymentSystemName={params.psName}
            PaymentReference={params.paymentId}
            Code={params.code}
            isIframeComponent={this.props.isIframeComponent}
          />
        ) : null}
      </>
    );
  }
}

export default FinalPage;
