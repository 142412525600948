import React, {Component} from 'react';
import {parseURL} from '../../helper/helper';
import NotFoundPage from '../notFoundPage/notFoundPage';
import QrBody from '../QrBody/QrBody';
import IframeQrBody from '../IframeQrBody/IframeQrBody';

class QRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      url: this.props.url ? this.props.url : window.location.href,
      requestParamQr: this.props.requestParamQr,
      requestParamBarCode: this.props.requestParamBarCode,
      isInvalidUrl: false
    };

    this.parseURL = parseURL.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    if (!this.state.url.includes('&')) {
      this.setState({isInvalidUrl: true});
    } else {
      const urlObj = this.parseURL(this.state.url);
      const urlParams = urlObj.params;
      this.setState({params: urlParams});
    }
  }

  search(term) {
    let results = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const item in this.state.params) {
      if (item.toLowerCase().includes(term.toLowerCase()) || this.state.params[item].toLowerCase().includes(term.toLowerCase())) {
        results = {...results, [item]: this.state.params[item]};
      }
    }
    return {results};
  }


  render() {
    if (this.state.isInvalidUrl) {
      return <NotFoundPage />;
    }

    if (this.props.isIframeComponent) {
      return (
        <IframeQrBody
          themeColor={this.state.params.backgroundColor}
          PaymentSystemId={this.state.params.ps}
          qrImage={this.state.params.QRCODE}
          qrCode={this.state.params.QR}
          barCode={this.state.params.BarCode}
          requestParamQr={this.state.requestParamQr}
          requestParamBarCode={this.state.requestParamBarCode}
        />
      );
    }

    return (
      <QrBody
        TransactionID={this.state.params.clientRequestId}
        UserID={this.state.params.customerId}
        Amount={this.state.params.transactionAmount}
        Message={this.state.params.mes}
        Currency={this.state.params.currency}
        ServiceProviderId={this.state.params.sp}
        PaymentSystemId={this.state.params.ps}
        PaymentSystemName={this.state.params.psName}
        qrImage={this.state.params.QRCODE}
        qrCode={this.state.params.QR}
        barCode={this.state.params.BarCode}
        isNew={this.state.params.isNew}
        CryptoCurrency={this.state.params.cryptoCurrency}
        CryptoAmount={this.state.params.cryptoAmount}
        CopiedText={this.search('Copy')}
        backUrl={this.state.params.backUrl}
        requestParamQr={this.state.requestParamQr}
        requestParamBarCode={this.state.requestParamBarCode}
        Language={this.state.params.lang}
        generateQrCode={this.state.params.generateQrCode}
        createQR={this.state.params.createQR}
        isIframeComponent={this.props.isIframeComponent}
        CopyWalletAddress={this.state.params.CopyWalletAddress}
        CopyDestinationTag={this.state.params.CopyDestinationTag}
      />
    );
  }
}

export default QRPage;
