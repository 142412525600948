import React, {Component} from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import {PS_ID} from '../../constants/common';

class iframeQrBody extends Component {
  render() {
    return (
      <main className="main-iframe-wrapper" style={{background: this.props.themeColor}}>
        {this.props?.qrImage && this.props.PaymentSystemId !== PS_ID.OPLATI_ONLINE && (
        <div className="qrCode-wrapper__iframe wrapper-barCode">
          <img src={decodeURIComponent(this.props.qrImage)} alt="QR CODE" />
        </div>
        )}
        {this.props?.requestParamQr && (
          <>
            <div className="qrCode-wrapper__iframe">
              <img src={decodeURIComponent(this.props.requestParamQr)} alt="QR CODE" />
            </div>
          </>
        )}
        {this.props.PaymentSystemId === PS_ID.OPLATI_ONLINE && (
        <div className="qrCode-wrapper__iframe">
          <QRCode value={this.props.qrCode} size="250" />
        </div>
        )}
        {this.props?.requestParamBarCode && (
          <>
            <div className="qrCode-wrapper__iframe barCode-wrapper-base">
              <img
                src={`data:image/jpeg;base64,${this.props.requestParamBarCode}`}
                alt="QR CODE"
              />
            </div>
          </>
        )}
        {this.props?.barCode && (
          <div className="barcode-wrapper">
            <Barcode value={this.props.barCode} />
          </div>
        )}
      </main>
    );
  }
}

export default iframeQrBody;
