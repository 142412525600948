import terrible from '../assets/images/terrible.svg';
import bad from '../assets/images/bad.svg';
import okay from '../assets/images/okay.svg';
import good from '../assets/images/good.svg';
import great from '../assets/images/great.svg';

/**
 * Constant values that commonly used in project
 */

export const TRANSACTION_MESSAGE_TYPES = {
  SUCCESS: '1',
  ERROR: '2',
  IN_PROGRESS: '3'
};

export const SITE = {
  IS_NEW: '1'
};

export const PS_ID = {
  OPLATI_ONLINE: '4880',
  ZENNPAYZ_HAVALE: '1714',
  PIX: '1705',
  PIX_TO_PAY: '1767'
};

export const SITE_ID = {
  SITE81: '81',
  SITE1476: '1476',
  SITE77: '77'
};

export const TRANSACTION_RESPONSE = {
  SUCCESS: 200
};

export const ERROR_CODES = {
  DEFAULT_BETERA_MESSAGE: 'DEFAULTBETERAMESSAGE'
};

export const RATING_INFO = [
  {
    KEY: 1,
    IMG: terrible,
    NAME: 'page.terrible'
  },
  {
    KEY: 2,
    IMG: bad,
    NAME: 'page.bad'
  },
  {
    KEY: 3,
    IMG: okay,
    NAME: 'page.okay'
  },
  {
    KEY: 4,
    IMG: good,
    NAME: 'page.good'
  },
  {
    KEY: 5,
    IMG: great,
    NAME: 'page.great'
  }
];

export const LINE_INDEXES = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3
};
